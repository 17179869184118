<template>
  <div>
    <h1 :class="$style.pageTitle">お知らせ一覧</h1>
    <section class="card">
      <div class="card-header">
        <div class="pull-right mt-2">
          <div class="d-inline-block">
            <router-link :to="{ name: 'notificationCreate' }">
              <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">作成</a-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="loading" class="text-center">
          <a-spin tip="Loading..." />
        </div>
        <div v-if="!loading">
          <a-table
            :columns="columns"
            :data-source="notifications"
            :pagination="{ pageSize: 100 }"
            rowKey="id"
            :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 : $style.stripe1"
          >
            <span slot="date" slot-scope="date">{{ date | formatDate }}</span>
            <span slot="is_public" slot-scope="is_public">{{ itemIsPublic[is_public] }}</span>
            <span slot="update" slot-scope="id, record">
              <router-link :to="{ name: 'notificationUpdate', params: { id: record.id }}">
                <a-button style="padding: 0 10px; margin-right: 4px;">編集</a-button>
              </router-link>
            </span>
          </a-table>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      loading: true,
      notifications: [],
      itemIsPublic: ['非公開', '公開'],

      columns: [
        {
          title: '日付',
          dataIndex: 'date',
          scopedSlots: { customRender: 'date' },
          sorter: (a, b) => {
            return new Date(a.date) - new Date(b.date)
          },
          defaultSortOrder: 'descend',
          width: 120,
          align: 'center',
        },
        {
          title: 'カテゴリ',
          dataIndex: 'category',
          scopedSlots: { customRender: 'category' },
          sorter: (a, b) => {
            return (a.category || '').localeCompare(b.category)
          },
          width: 160,
        },
        {
          title: 'タイトル',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
          sorter: (a, b) => {
            return (a.title || '').localeCompare(b.title)
          },
        },
        {
          title: '公開設定',
          dataIndex: 'is_public',
          scopedSlots: { customRender: 'is_public' },
          sorter: (a, b) => {
            return a.is_public - b.is_public
          },
          width: 120,
          align: 'center',
        },
        {
          title: '編集',
          dataIndex: '',
          scopedSlots: { customRender: 'update' },
          sorter: false,
          width: 90,
          align: 'center',
        },
      ],
    }
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'notifications/all')
      .then(response => {
        this.notifications = response
        this.loading = false
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': お知らせ一覧の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  filters: {
    formatDate: function (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD')
      } else {
        return '-'
      }
    },
  },
}
</script>
