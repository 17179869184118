var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("お知らせ一覧")]),
    _c("section", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "pull-right mt-2" }, [
          _c(
            "div",
            { staticClass: "d-inline-block" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "notificationCreate" } } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-3",
                      class: _vm.$style.filledBtn,
                      attrs: {
                        type: "primary",
                        autoInsertSpaceInButton: "false"
                      }
                    },
                    [_vm._v("作成")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "card-body" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "text-center" },
              [_c("a-spin", { attrs: { tip: "Loading..." } })],
              1
            )
          : _vm._e(),
        !_vm.loading
          ? _c(
              "div",
              [
                _c("a-table", {
                  attrs: {
                    columns: _vm.columns,
                    "data-source": _vm.notifications,
                    pagination: { pageSize: 100 },
                    rowKey: "id",
                    rowClassName: function(r, i) {
                      return i % 2 === 0
                        ? _vm.$style.stripe0
                        : _vm.$style.stripe1
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "date",
                        fn: function(date) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm._f("formatDate")(date)))
                          ])
                        }
                      },
                      {
                        key: "is_public",
                        fn: function(is_public) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm.itemIsPublic[is_public]))
                          ])
                        }
                      },
                      {
                        key: "update",
                        fn: function(id, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "notificationUpdate",
                                      params: { id: record.id }
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        padding: "0 10px",
                                        "margin-right": "4px"
                                      }
                                    },
                                    [_vm._v("編集")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }
                      }
                    ],
                    null,
                    false,
                    3058039528
                  )
                })
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }